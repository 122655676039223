import React from 'react';
import './ReleaseNotes.css'; // Importing a CSS file for styling

const ReleaseNotes = () => {
  return (
    <div id="release-notes">
      <h1>Release Notes</h1>
      
      <div id="release">
        <h2>Version 1.1.0 (April 2024)</h2>
        <h3>New Features 🎉</h3>
        <p>Medication Reminder: We've added a new feature that allows you to enable or disable medication reminders for patient</p>
        <h3>Improvements 🚀</h3>
        <p>UI Changes: We've made some significant changes to the landing page. The new design is more user-friendly and intuitive.</p>
        <p>Added Push Notification feature Account Section</p>
        <p>Added Custom Reminder Template Account Section</p>

        <h3>Bug Fixes 🐛</h3>
        <p>No major bugs have been reported or fixed in this release.</p>
        <h3>Security Updates 🔒</h3>
        <p>No major security updates in this release.</p>
      </div>


      <div id="release">
        <h2>Version 1.1.0 (May 2024)</h2>
        <h3>New Features 🎉</h3>
        <p>Added Fireabse</p>
        <p>Added Chat Push Notifications</p>
        <p>User Feedback Screen added </p>
       
        
        <h3>Bug Fixes 🐛</h3>
        <p>Fix the bug of forgot password</p>
        <h3>Security Updates 🔒</h3>
        <p>Improved Auth Security</p>
      </div>

      <div id="release">
        <h2>Version 1.1.0 (June 2024)</h2>
        
        <h3>Improvements 🚀</h3>
        <p>Improved The flow of creating referral.</p>
        <p>Changes in User Feedback Screen </p>

      
        <h3>Security Updates 🔒</h3>
        <p>Added logout api</p>
      </div>

      <div id="release">
        <h2>Version 1.1.1 july 2024)</h2>
        
        <h3>New Features 🚀</h3>
        <p>Added Fax Option for Provider Referral</p>
       

      
      
      </div>
      <div id="release">
        <h2>Version 1.1.2 August 2024)</h2>
        
      
        <p>Added Fax Option for Provider Referral</p>
        <p>Minor Changes in Apis</p>


      
      
      </div>

      
    </div>
  );
};

export default ReleaseNotes;
