import { Alex, Colleen, harry, Nicgay, saanchi, shawan, shrey, upinder, vivak ,bob} from "./images";

export const jobStatus = {
  Pending: "Pending",
  Completed: "Completed",
  Expired: "Expired",
};
export const jobStatusColor = {
  Pending: { background: "#dfc500" },
  Completed: { background: "#55B670" },
  Expired: { background: "red" },
};
export const patientTabs = {
  PERSONAL_INFORMATION: "personal-Information",
  SERVICE_LIST: "service-list",
  MEDICAL_RECORDS: "medical-records",
  PROVIDER_REFERRAL: "PROVIDER_REFERRAL",
  Visit_History: "Visit_History",
  NOTES: "NOTES",


};

export const getjobStatus = {
  0: {
    orderStatus: "Pending",
    class: "Pending",
  },
  1: {
    orderStatus: "Assigned",
    class: "Pending",
  },
  2: {
    orderStatus: "Confirmed",
    class: "Confirmed",
  },
  3: {
    orderStatus: "Cancelled",
    class: "Cancelled",
  },
  5: {
    orderStatus: "N/A",
    class: "#EC7F78",
  },
  4: {
    orderStatus: "In Progress",
    class: "Pending",
  },
  6: {
    orderStatus: "Completed",
    class: "Completed",
  },
};

export const serviceSteps = {
  SELECT_USER: "SELECT_USER",
  EDIT_USER_DETAIL: "EDIT_USER_DETAIL",
  SELECT_ADDRESS: "SELECT_ADDRESS",
  SELECT_SERVICE: "SELECT_SERVICE",
  SCHEDULE_SERVICE: "SCHEDULE_SERVICE",
  ADD_PRESCRIPTION: "ADD_PRESCRIPTION",
  SELECT_HOME_OR_DOCTOR :"SELECT_HOME_OR_DOCTOR",
  SELECT_CLINIC_DOCTOR :"SELECT_CLINIC_DOCTOR",
  SELECT_LOCATION: "SELECT_LOCATION",
};

export const helpandSupportText = [
  {
    title: "Provider related",
    desc: [
      "Our medical services app is designed to make it easier for patients to access healthcare from the comfort of their own home. With our app, patients can book appointments, communicate with their doctors, access their medical records, and much more. Our team of experienced developers and healthcare professionals have worked together to create a user-friendly app that meets the unique needs of both patients and medical providers. Using the latest technology, our app allows patients to easily book appointments with their preferred doctors, and receive reminders and alerts for upcoming appointments. They can also securely message their doctors, request prescription refills, and access their medical records at any time. For medical providers, our app offers a convenient platform for managing appointments, communicating with patients, and maintaining accurate records. With our medical services app, we are committed to improving access to healthcare and making it easier for patients and providers to connect.",
    ],
  },
  {
    title: "Service related questions",
    desc: [
      "Who comes to my home to provide medical care?",
      "MedSetGo selects the top reviewed, and most timely available care provider that you need from the vetted and approved MedSetGo partners",
      "What are the MedSetGo COVID-19 protocols? MedSetGo ensures that all the practitioners both in-house and out serving patients are fully vaccinated and donned in full PPE for both their safety and yours",
    ],
  },
  {
    title: "Payments related issue",
    desc: [
      "Is MedSetGo free to use?",
      "Yes, MedSetGo is a completely free software that helps coordinate your home care needs! The best home care services in your area will be at your fingertips and you will pay only what is necessary to get the care you need at home.",
      "Am I getting the best price?",
      "Absolutely! MedSetGo analyzes the rates in your specific geographic region and provides you with services at the most affordable rates.",
      "Is there any negotiation on the final price? Our prices are fixed and standardized so that we can guarantee our timeliness and on-demand booking!",
    ],
  },
  {
    title: "Other issue",
    desc: [
      "How do I know that whoever is coming to my house is safe?",
      "MedSetGo vets all providers before onboarding them onto the platform then keeps track of their behavior on the job You also have the ability to make Live Reports and Requests on the App before, during and after your order!",
      "Can I book services for my family members?",
      'Yes! One of the things we love about MedSetGo is that you can not only order services for yourself, but also for your loved ones. You can create your own "family" within the app, for whom you can easily re-order services for your family members in the future.',
      "How do I know that information is safe?",
      "MedSetGo is a HIPAA compliant platform, and we ensure that any contract we sign with our service partners includes strict privacy policies that ensure proper encryption of your information.",
      "What should I do if I forgot my password?",
      'Click "Forgot Password" and check vour email for a link to reset your password.',
    ],
  },
];

export const addressTypes = {
  Home: "Home",
  Office: "Office",
  Other: "Other",
};

export const patientLocation = [
  {
    value: 1,
    label: "Home",
  },
  {
    value: 2,
    label: "RCFE",
  },
  {
    value: 3,
    label: "SNF",
  },
  {
    value: 4,
    label: "ALF",
  },
];

//  export const DME= [
//     {
//       id: 1,
//       value: 'Wheelchair',
//       isSelected: false,
//     },
//     {
//       id: 2,
//       value: 'Hospital Bed',
//       isSelected: false,
//     },
//     {
//       id: 3,
//       value: 'Oxygen',
//       isSelected: false,
//     },
//     {
//       id: 4,
//       value: 'Hoyer Lift',
//       isSelected: false,
//     },
//     {
//       id: 5,
//       value: 'Other',
//       isSelected: false,
//     },
//   ]
export const DME = [
  {
    value: 1,
    label: "Wheelchair",
    isSelected: false,
  },
  {
    value: 2,
    label: "Hospital Bed",
    isSelected: false,
  },
  {
    value: 3,
    label: "Oxygen",
    isSelected: false,
  },
  {
    value: 4,
    label: "Hoyer Lift",
    isSelected: false,
  },
  {
    value: 5,
    label: "Other",
    isSelected: false,
  },
];

export const Dnr = [
  {
    value: 1,
    label: "YES",
  },
  {
    value: 2,
    label: "NO",
  },
];
export const ad = [
  {
    value: 1,
    label: "YES",
    isSelected: false,
  },
  {
    value: 2,
    label: "NO",
    isSelected: false,
  },
];

export const days = [
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
  { label: "Sun", value: 7 },
];

export const testimonialData = [
  {
    description:
      "Had an awesome experience with my physical therapist. Came to my house on time and taught me all the exercises I needed to do in order to feel right after my hip surgery. Thank you MedSetGo!",
    username: "Stephanie Harris",
    icon: "assets/images/testimonial-icon-1.svg",
  },
  {
    description:
      "Excellent experience! It was so easy to find exactly what I needed for my 78-year-old grandmother.",
    username: "John Smith",
    icon: "assets/images/testimonial.png",
  },
  {
    description:
      "This has made discharge coordination so much more efficient and has made my job as a clinic case manager so much easier!",
    username: "Colin",
    icon: "assets/images/testimonial-icon-3.svg",
  },
  {
    description:
      "Had an awesome experience with my physical therapist. Came to my house on time and taught me all the exercises I needed to do in order to feel right after my hip surgery. Thank you MedSetGo!",
    username: "Stephanie Harris",
    icon: "assets/images/testimonial-icon-1.svg",
  },
  {
    description:
      "Excellent experience! It was so easy to find exactly what I needed for my 78-year-old grandmother.",
    username: "John Smith",
    icon: "assets/images/testimonial.png",
  },
  {
    description:
      "This has made discharge coordination so much more efficient and has made my job as a clinic case manager so much easier!",
    username: "Colin",
    icon: "assets/images/testimonial-icon-3.svg",
  },
];

export const testAccounts = [
  // demo accounts for prod
  "msguser@yopmail.com",
  "democs@yopmail.com",
  "msgcs@yopmail.com",
  "abhishekksw@gmail.com",
  "testathena@yopmail.com",
  // demo accounts for dev

  "abhi69@yopmail.com",
  "casemanagerathena@yopmail.com",
  "doctortest@medsetgo.com",
  "vivek@yopmail.com",
  "physicalcase@yopmail.com"


];

export const userTypes = {
  USER:'1',
  CASE_MANAGER: "3",
  DOCTOR :"4"
};


export const teamMembers = [
  // {
  //     name: "Noel Krive",
  //     desc: "3x founder, 3 exits, partner in venture studio",
  //     linkedin: "https://www.linkedin.com/in/noel-zimmerman-krive-6b739421/",
  //     pic: neol
  // },
  {
      name: "Shrey Kapoor",
      desc: "MBA, MD candidate at Johns Hopkins",
      title: "Founder and CEO",
      linkedin: "https://www.linkedin.com/in/shreykapoor1/",
      pic: shrey
  },
  {
      name: "Vivek Kapoor",
      title :'Chief Product and Technology Officer',
      desc: `20+ years of software experience, founder of TownConnect Inc.`,
      linkedin: "https://www.linkedin.com/in/vivek10/",
      pic: vivak
  },
  {
      name: "Dr. Nic Gay",
      title:'Chief Medical Officer',
      desc: "Orthopedic surgeon, co- founder of SimpleTherapy",
      linkedin: "https://www.linkedin.com/in/nicgaymd/",
      pic: Nicgay
  },
  {
      name: "Shawn Powers",
      title:'Chief Operating Officer',
      // desc: "Prior system-wide Vice President of 29 hospital system, Texas Health Resources",
      desc: "Prior system-wide Vice President of 29 hospital system, Texas Health Resources",
      linkedin: "https://www.linkedin.com/in/-shawnpowers/",
      pic: shawan
  },
  {
      name: "Saanchi Kapoor,Business Strategy & Marketing",
      desc: "As a co-founder of Medsetgo she is working on customer acquisition strategy and helping build AI recommendations models for home health services.",
      linkedin: "https://www.linkedin.com/in/saanchi-kapoor-7a6394217/",
      pic: saanchi
  },
  {
      name: "Dr. Alex Sah",
      desc: "Orthopedic Surgeon, President American Hip Foundation; Co-Director of Institute for Joint Restoration",
      linkedin: "https://www.linkedin.com/in/alexander-sah-300189174/",
      pic: Alex
  },
  {
      name: "Dr. Upinder Singh",
      desc: "Chief Medical Officer of ACO group; residency program director in Las Vegas; BOD of home health agency; BOD of insurance group",
      linkedin: "",
      pic: upinder
  },

  {
      name: "Harry Kharodh",
      desc: "30+ years in software, previously Kaiser executive (office of the CTO)",
      linkedin: "https://www.linkedin.com/in/harry-kharodh-90b4091/",
      pic: harry
  },

  {
      name: "Colleen Cutliffe",
      desc: "Unicorn founder of Pendulum Therapeutics",
      linkedin: "https://www.linkedin.com/in/colleen-cutcliffe-9021449/",
      pic: Colleen
  },
//   {
//     name: "Bob Bunker",
//     desc: "Bob Bunker is an accomplished Healthcare executive with over 40 years of experience as a Board Chairman and CEO",
//     linkedin: "https://www.linkedin.com/in/bob-bunker-49b3b527b/",
//     pic: bob
// }
]